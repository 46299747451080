.roadmap {
    background-image: url('../../asset/image/roadmapback.jpg');
    ;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: 5%;
}

.roadmap::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    left: 0;
    top: 0;
}

.roadmap-wraper {
    position: relative;
    z-index: 2;
}

.parse-step {
    color: #fde59c;
    position: relative;
}

.parse-des {
    position: absolute;
    top: 80px;
    left: 15%;
    text-align: left;
    list-style: disc !important;
}

.parse-des li {
    list-style: disc !important;
}

.mob-img {
    display: none;
}

.last_rd_step {
    margin-top: 115px;
}

@media only screen and (max-width:782px) {
    .parse-des {
        position: absolute;
        top: 120px;
        left: 2%;
        text-align: left;
        list-style: disc !important;
    }
    .mob-img {
        display: block;
    }
    .parse-img {
        display: none;
    }
    .last_rd_step {
        margin-top: 95px;
    }
}

@media only screen and (max-width:382px) {
    .last_rd_step {
        margin-top: 130px;
    }
}

@media only screen and (max-width:322px) {
    .last_rd_step {
        margin-top: 160px;
    }
}