.offlineEvents {
    background-color: #000;
    padding-bottom: 1%;
}

.offline-event-wraper {
    border: 1px solid #fd9;
    border-radius: 20px;
    padding: 40px;
    text-align: left;
    color: #fd9;
    margin-bottom: 50px;
}

.off-event-img {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.off-event-img img {
    width: 48%;
    max-height: 400px;
    padding: 20px 0;
}

.support-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    letter-spacing: 0.095em;
}

.support-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 176.5%;
    /* or 35px */
    letter-spacing: 0.095em;
}

@media only screen and (max-width:780px) {
    .off-event-img {
        flex-direction: column;
    }
    .off-event-img img {
        width: 100%;
        max-height: 400px;
        padding: 15px 0;
    }
}

.offline-learn-more button {
    border: none;
    outline: none;
    border: 1px solid #fd9;
    border-radius: 8px;
    padding: 7px 16px;
    background: transparent;
    color: #fd9;
    display: none;
}

@media (max-width:550px) {
    .offline-learn-more {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .offline-learn-more button {
        display: block;
    }
    .show-text {
        display: none;
    }
    .show-text-active {
        display: block;
    }
    .support-title,
    .support-text {
        text-align: center;
    }
}