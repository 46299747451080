.metaEvents {
    background-image: url('../../asset/image/eventBackground.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.eventCard {
    background-color: rgba(0, 0, 0, 0.54);
    color: #fde59c;
    border: 1px solid #fde59c;
    border-radius: 20px;
    padding: 30px;
}

.event-text {
    min-height: 140px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 176.5%;
    /* or 25px */
    text-align: justify;
    letter-spacing: 0.095em;
}

.event-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    letter-spacing: 0.095em;
}

.desktop-metaevents .eventCard {
    height: 550px;
}

.mobile-metaevents {
    display: none;
}

.mobile-metaevents .eventCard {
    padding: 30px 14px;
}

.mobile-metaevents .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 13px !important;
    border: none;
    overflow: hidden;
    flex-direction: column;
    background-color: transparent;
}

.mobile-metaevents .swiper-pagination-bullet {
    background: #b9b4b4 !important;
}

@media (max-width:550px) {
    .desktop-metaevents {
        display: none;
    }
    .mobile-metaevents {
        display: block;
    }
}

@media only screen and (max-width:489px) {
    .exibition-imgs-bg {
        background: #000;
        padding-bottom: 16px;
        border-radius: 8px;
        border: 1px solid #fde59c;
        padding-top: 45px;
    }
    .exibition-imgs-bg .eventCard {
        padding: 0;
        border-radius: 0px;
        border: none;
    }
    .exibition-imgs-bg .eventCard {
        margin-top: -32px;
    }
}