@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* {
    font-family: 'Poppins', sans-serif;
    text-decoration: none !important;
}

.aboutUs {
    position: relative;
    top: 62%;
}

.aboutUs p {
    font-family: 'Poppins', sans-serif;
    color: #FDE59C;
    padding: 100px 200px 32px 200px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 257%;
    /* or 41px */
    letter-spacing: 0.095em;
}

.social-section {
    display: flex;
    color: aliceblue;
    align-items: center;
    justify-content: center;
}

.social-section i {
    width: 50px;
    height: 50px;
    background-color: #5F5F5F;
    border-radius: 50%;
    margin: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: #FDE59C;
}

.artist {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.aboutus-img {
    padding-top: 0px;
}

.artist-img {
    width: 49%;
    align-items: center;
    text-align: center;
    margin: 12px auto 0 auto;
}

@media only screen and (max-width:768px) {
    .aboutUs p {
        /* padding: 100px 12px; */
        padding: 50px 12px 0px 12px;
    }
    .social-section i {
        width: 50px;
        height: 50px;
        background-color: #5F5F5F;
        border-radius: 50%;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        color: #FDE59C;
    }
    .aboutUs {
        position: relative;
        top: 50%;
    }
}

@media screen and (max-width:480px) {
    .artist-img {
        width: 90%;
        margin: 0 auto;
        display: none;
    }
    .social-section {
        margin-bottom: 12px;
    }
}