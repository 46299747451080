* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
}

.topbanner {
    /* background-image:url('../../asset/image/shutterstock_312612356.jpg'); */
    height: 2650px;
    /* width: 100%; */
    overflow: hidden;
    position: relative;
}

.navigation-position {
    position: fixed;
    z-index: 99;
}

.banner-background {
    /* margin-top: -100px; */
    position: fixed;
    z-index: -5 !important;
    left: 0;
    width: 100%;
    /* height: 2500px; */
}

@media only screen and (max-width:979px) {
    .banner-background {
        width: auto;
        height: 100vh;
    }
}

.topbanner::before {
    position: absolute;
    content: '';
    width: 100%;
    background-color: rgba(0, 0, 0, 0.64);
    height: 102%;
    left: 0;
    top: 0;
}

.banner-top-content {
    position: relative;
}

.banner-logo {
    position: absolute;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    top: 200px;
}

.discord {
    position: absolute;
    top: 380px;
    cursor: pointer;
}

.minit {
    position: absolute;
    top: 500px;
    cursor: pointer;
}

.learn {
    position: absolute;
    top: 700px;
    cursor: pointer;
}

.sideManu-wraper {
    position: absolute;
    right: -10px;
    top: -5px;
}

.sidemanu-area {
    height: 102vh;
    right: -10px;
}

.edenBox {
    top: 1259px;
}

.side-area-icon {
    position: absolute;
    display: flex;
    flex-direction: column;
    color: #fde59c;
    align-items: center;
    justify-content: center;
    top: 30%;
    right: 18px;
    font-size: 25px;
}

.doted {
    width: 5px;
    height: 5px;
    background-color: #fcd970;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.space {
    height: 40px;
}

.artist-img {
    padding-top: 150px;
}

@media only screen and (max-width:979px) {
    .sideManu-wraper {
        display: none;
    }
    .edenBox {
        top: 1150px;
    }
    .discord {
        width: 80% !important;
    }
    .minit {
        width: 80% !important;
    }
}

.mint-edn-button {
    width: 100%;
    max-width: 550px;
}

.mint-edn-heading {
    color: #fcd970;
    letter-spacing: 10px;
    position: relative;
    top: 900px;
    opacity: 0.9;
    text-transform: uppercase;
}

@media(max-width:979px) {
    .mint-edn-heading {
        top: 876px;
        letter-spacing: 9px;
        font-size: 18px;
        padding: 0 10px;
    }
}