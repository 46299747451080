.banner {
    position: relative;
    height: 100vh;
}

.artistRecruit-banner {
    width: 100%;
    height: 100vh;
    z-index: 1;
    position: absolute;
    left: 0;
}

.artistRecruite-banner-logo {
    z-index: 3;
    /* left: 50%;
    right: 50%;
    translate:transform(-50%,-50%); */
    position: relative;
    top: 15%;
    width: 40%;
}

.banner::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

.back-style {
    background-color: #fff;
}

.container-color {
    background-color: #fff;
    padding-bottom: 20px;
    margin-bottom: -50px;
}

.desktop2-beg {
    background-color: #fff;
}

.skatch {
    width: 100%;
}

.mission-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 170%;
    /* or 37px */
    text-align: center;
    letter-spacing: 0.385em;
    color: #000000;
}

.mission-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 239.5%;
    /* or 41px */
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 0.025em;
    color: #000000;
}

.acg-artist {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
    /* or 34px */
    text-align: center;
    letter-spacing: 0.385em;
    color: #000000;
}

.acg-artist-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 239.5%;
    /* or 43px */
    text-align: center;
    letter-spacing: 0.025em;
    color: #000000;
}

@media screen and (max-width: 768px) {}

@media only screen and (max-width: 768px) {
    .recuiter_bg {
        background-image: url(../../../imagee/recuiterBg.jpg);
        /* background-image: url(../../../imagee/secondBanner.jpg); */
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .artistRecruite-banner-logo {
        top: 21%;
        width: 94%;
    }
}