.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

:root {
    --primary: #fde59c;
    --primary-dark: #e5ad00;
}

.learn-more {
    border: none;
    outline: none;
    border: 1px solid #fd9;
    border-radius: 8px;
    padding: 7px 16px;
    background: transparent;
    color: #fd9;
}

.bg-pr {
    background-color: var(--primary);
}

.text_pr {
    color: var(--primary);
}

.text_pr_dark {
    color: var(--primary-dark);
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: -3px!important;
}

.swiper-pagination-bullet-active {
    background: var(--primary)!important;
}