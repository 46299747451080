.bonuse-section{
    background-image:url('../../asset/image/bonuse.png');
    background-position: center ;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.bonuse-section::after{
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
}
.bonuse-content{
    display: flex;
    text-align: justify;
 
    justify-content: space-between;
}
.section-wraper{
    position: relative;
    z-index: 2;
    color:#fde59c;
}
.bonuse-box{
    border: 1.5px solid #fde59c;
    border-radius: 20px;
    padding: 5%;
}
.bonuse-img{
    width: 100%;
}
.bonus-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 176.5%;
    /* or 35px */

    letter-spacing: 0.095em;
}
.bonus-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */

    letter-spacing: 0.095em;
}
.left{
    width: 50%;
    padding-top: 40px;
}
.right{
    width: 50%;
    padding-top: 40px;
}
@media only screen and (max-width:979px){
    .bonuse-content{
        display: flex;
        text-align: justify;
        justify-content: space-between;
        flex-direction:column-reverse;
    }
    .left{
        width: 100%;
        padding-top: 40px;
    }
    .right{
        width: 100%;
        padding-top: 40px;
    }
}


.bonus-learn-more button{
    border: none;
    outline: none;
    border: 1px solid #fd9;
    border-radius: 8px;
    padding: 7px 16px;
    background: transparent;
    color: #fd9;
    display: none;
}

@media (max-width:550px){
   .bonus-learn-more {
    display: flex;
    align-items: center;
    justify-content: center;
}
.bonus-learn-more button{
    display: block;
}
.show-bonus-text {
    display: none;
}
.show-bonus-text-active{
    display: block;
} 
}