.card-heading {
    padding-top: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 239.5%;
    /* or 43px */
    text-align: center;
    letter-spacing: 0.025em;
    color: #000000;
}

.card-first-row {
    text-align: left;
}

.card-first-row h1 {
    text-align: left;
    color: #fbbe09;
    font-size: 30px;
    font-weight: 800;
    padding-bottom: 30px;
    padding-top: 30px;
}

.card-box {
    border: 1px solid #fbbe09;
    /* border-radius: 15px; */
    position: relative;
}

@media (min-width: 768px) {
    .card-box {
        height: 100%;
    }
}

.corner-left {
    position: absolute;
    left: 3px;
    top: 3px;
    width: 8%;
}

.corner-right {
    position: absolute;
    right: 3px;
    top: 3px;
    width: 8%;
}

.card-background {
    background-color: #fff;
}

.guide-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 176.5%;
    /* or 28px */
    text-align: justify;
    letter-spacing: 0.005em;
    /* color: #313131; */
    color: #000;
}

@media only screen and (max-width:768px) {
    .card-box {
        border-radius: 12px;
    }
    .text_card_slider .swiper-slide {
        height: unset !important;
        min-height: unset !important;
        display: flex;
    }
    .corner-left {
        /* display: none; */
    }
    .corner-right {
        /* display: none; */
    }
}